import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import FaqGrid from "../components/faq-grid";
import Seo from "../components/seo";

const FaqPage = () => {
  const { allStrapiFaq, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiFaq {
        nodes {
          question
          answer {
            data {
              childMarkdownRemark {
                html
                rawMarkdownBody
              }
            }
          }
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Blog" }} />

      <main>
        <FaqGrid questions={allStrapiFaq.nodes} />
      </main>
    </Layout>
  );
};

export default FaqPage;
