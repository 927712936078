import React, { useState } from "react";
import { Link, graphql } from "gatsby";
// import Accordion from "./Accordion/Accordion";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
const FaqCard = ({ question }) => {
  const [folded, setFolded] = useState(true);

  const handleFolded = () => setFolded(!folded);

  // console.log(question);
  return (
    <div class="ud-w-full  ud-px-4 md:ud-w-2/3 lg:ud-w-1/2 xl:ud-w-1/3">
      <div
        class="
    wow fadeInUp
    ud-relative
    ud-mb-10 ud-overflow-hidden ud-rounded-md ud-bg-white
    ud-shadow-one
    dark:ud-bg-dark
  "
        data-wow-delay=".1s"
      >
        <div
          onClick={handleFolded}
          style={{ cursor: "pointer" }}
          class="
            ud-p-6
      ud-text-body-color
      sm:ud-p-8
      md:ud-py-8 md:ud-px-6
      lg:ud-p-8
      xl:ud-py-8 xl:ud-px-5
      2xl:ud-p-8
    "
        >
          {" "}
          <div class="ud-flex ud-justify-between">
            <h2 class="text-3xl">{question.question}</h2>
            {folded ? (
              <BsChevronDown className="ud-h-8 ud-w-8" />
            ) : (
              <BsChevronUp className="ud-h-8 ud-w-8" />
            )}
          </div>
          {!folded && (
            <div
              class="
          ud-mt-4
          ud-border-t
          ud-border-body-color
          ud-border-opacity-10
          ud-pr-5
          dark:ud-border-white
          dark:ud-border-opacity-10 xl:ud-mr-3 xl:ud-pr-3
          2xl:ud-mr-5 2xl:ud-pr-5
        "
            >
              <div
                className="ud-mt-4"
                dangerouslySetInnerHTML={{
                  __html: question.answer.data.childMarkdownRemark.html,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqCard;
